import React from 'react';
import { Typography, Grid } from '@mui/material';

const Home = () => {

    return(
        <>
            <Grid container spacing={2} sx={{ minHeight: "200px" }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h4" align="left">ご指定のページは見つかりませんでした。</Typography>
                </Grid>
            </Grid>
        </>
    );

};

export default Home;