import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, TextField, Grid, Button, Select, MenuItem, Checkbox } from '@mui/material';

const EmailVaildPattern = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";
const PhoneNumberVaildPattern = "[0-9]{10,11}";


const SubmissionForm: React.FC<SubmissionObject> = (submissionObjectProps) => {

    const { fieldId } = useParams();
    const navigate = useNavigate();

    const [ isConfirming, setIsConfirming ] = useState<boolean>(false);
    const [ isTCPAgreed, setisTCPAgreed ] = useState<boolean>(false);
    const [ isCPAgreed, setisCPAgreed ] = useState<boolean>(false);
    const [ submission, setSubmission ] = useState<SubmissionObject>(submissionObjectProps);
    const [ options, setOptions ] = useState<Array<OptionObject>>([]);
    const [ selectedOptions, setSelectedOptions] = useState<Array<SelectedOptionObject>>([]);
    const [ cancelPolicies, setCancelPolicies ] = useState<Array<CancelPolicyObject>>([]);
    const userNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const phoneNumberRef = useRef<HTMLInputElement>(null);
    const [ userNameError, setUserNameError ] = useState(false);
    const [ emailError, setEmailError ] = useState(false);
    const [ phoneNumberError, setPhoneNumberError ] = useState(false);
    const [ numberOfPlayers, setNumberOfPlayers ] = useState<number>(1);
    const [ userName, setUserName ] = useState<string>(submission.user?.userName ? submission.user!.userName : '');
    const [ email, setEmail ] = useState<string>(submission.user?.email ? submission.user!.email : '');
    const [ phoneNumber, setPhoneNumber ] = useState<string>(submission.user?.phoneNumber ? submission.user!.phoneNumber : '');
    const [ bikou, setBikou ] = useState<string>('');
    const AMPLIFYAPINAME = 'fieldsystemuserapi';

    useEffect(()=>{
        getOptions();
        getCancelPolicies();
    }, []);

    //オプション情報の取得
    const getOptions = async () => {
        try{
            let optionsArry: Array<OptionObject> = [];
            if(submission.reservationFrame){
                const reservationFrame: ReservationFrameObject = submission.reservationFrame!;
                optionsArry = await API.get(AMPLIFYAPINAME, '/options', {queryStringParameters : {fieldId: reservationFrame.fieldId, frameId: reservationFrame.frameId, date: reservationFrame.date}});
            }else if(submission.eventObject){
                const eventObject: EventObject = submission.eventObject!;
                const getOptionsArrys = eventObject.usages.map((usage)=>{
                    return API.get(AMPLIFYAPINAME, '/options', {queryStringParameters : {fieldId: usage.fieldId, frameId: usage.frameId, date: usage.date}});
                });
                const optionsArrys: Array<Array<OptionObject>> = await Promise.all(getOptionsArrys);
                optionsArrys.forEach((optionsArray, index)=>{
                    optionsArray.forEach((option, index) => {
                        if(optionsArry[index]){
                            optionsArry[index].numberOfReservedStocks = optionsArry[index].numberOfReservedStocks < option.numberOfReservedStocks ? option.numberOfReservedStocks : optionsArry[index].numberOfReservedStocks
                        }else{
                            optionsArry.push(option);
                        }
                    });
                });
            }
            const selectedOptionArry: Array<SelectedOptionObject> = optionsArry.map((option) => {
                const selectedOption: SelectedOptionObject = {
                    optionId: option.id,
                    quantity: 0
                };
                return selectedOption;
            });
            setSelectedOptions(selectedOptionArry);
            setOptions(optionsArry);
        }catch(err){
            console.log('Error getOptions', err);
        }
    };

    //キャンセルポリシーの取得処理
    const getCancelPolicies = async () => {
        try{
            const fieldId = submission.reservationFrame ? submission.reservationFrame.fieldId : submission.eventObject!.fieldId;
            const policyType = submission.reservationFrame ? 'reservation': 'participant';
            const cancelPoliciesArry: Array<CancelPolicyObject> = await API.get(AMPLIFYAPINAME, '/cancelPolicy', {queryStringParameters: { fieldId: fieldId, policyType: policyType } } );
            setCancelPolicies(cancelPoliciesArry);
        }catch(err){
            console.log('Error:', err);
        }
    };

    //キャンセルポリシーの○日前という文字列の生成用変数
    const dateToDateString = (dtd: number) => {
        if(dtd == 0){
            return '当日';
        }else if(dtd == -1){
            return '当日（無連絡）';
        }else if(dtd == 1){
            return '前日';
        }else{
            return String(dtd) + '日前';
        }
    };
    const cancelFeeString = ( cancelPolicy: CancelPolicyObject) => {
        switch(cancelPolicy.cancelFeeType){
            case 'free':{
                return '無料';
            }
            case 'ratio':{
                return '支払予定金額の' + String(cancelPolicy.ratio * 100) + '%';
            }
            case 'fix':{
                return String(cancelPolicy.fix) + '円';
            }
        }
    }

    //オプション数量が変わったときの処理
    const handleOptionQuantityChange = (e: any) => {
        const index: number = Number(e.target.name);
        let value: number = isNaN(parseInt(e.target.value)) || e.target.value < 0 ? 0  : parseInt(e.target.value);
        if(options[index].numberOfStocks && options[index].numberOfStocks - options[index].numberOfReservedStocks < value){
            value = options[index].numberOfStocks - options[index].numberOfReservedStocks;
        }
        let selectedOptionArry: Array<SelectedOptionObject> = selectedOptions.map((selectedOption, index) => {
            return {
                optionId: selectedOption.optionId,
                quantity: selectedOption.quantity
            };
        });
        selectedOptionArry[index].quantity = value;
        setSelectedOptions(selectedOptionArry);
    };

    //テキストフィールドの値が変わったときの処理
    const handleTextFieldValueChange = (e: any) => {
        const attribute: string = e.target.getAttribute('name');
        switch(attribute){
            case 'userName':{
                const value: string = e.target.value;
                setUserName(value);
                break;
            }
            case 'numberOfPlayers':{
                const value: number = isNaN(parseInt(e.target.value)) || e.target.value < 1 ? 1  : parseInt(e.target.value)
                setNumberOfPlayers(value);
                break;
            }
            case 'email':{
                const value: string = e.target.value;
                setEmail(value);
                break;
            }
            case 'phoneNumber':{
                const value: string = e.target.value;
                setPhoneNumber(value);
                break;
            }
            case 'bikou': {
                const value: string = e.target.value;
                setBikou(value);
                break;
            }
            default:{

            }
        }
    }
    //バリデーション処理
    const formValidation = (): boolean => {
        let valid = true;
        //UserName
        const u = userNameRef?.current;
        if (u) {
            const ok = u.validity.valid;
            setUserNameError(!ok);
            valid &&= ok;
        }

        const e = emailRef?.current;
        if (e) {
            const ok = e.validity.valid;
            setEmailError(!ok);
            valid &&= ok;
        }

        const p = phoneNumberRef?.current;
        if (p) {
            const ok = p.validity.valid;
            setPhoneNumberError(!ok);
            valid &&= ok;
        }

        return valid;
    }

    //確認ボタン押されたときの処理
    const confirm = () => {
        //バリデーション
        if(formValidation()){
            //予約内容生成
            const submissionObject: SubmissionObject = {
                numberOfPlayers: numberOfPlayers,
                bikou: bikou,
                user: {
                    userName: userName,
                    email: email,
                    phoneNumber: phoneNumber
                },
                reservationFrame: submissionObjectProps.reservationFrame,
                eventObject: submissionObjectProps.eventObject,
                selectedOptions: selectedOptions
            }
            setSubmission(submissionObject);
            setIsConfirming(true);
            setisTCPAgreed(false);
        }else{
            console.log('バリデーションエラー');
        }
    };

    //利用規約とプライバシーポリシーへの同意チェックが操作されたときの処理
    const handleisTCPAgree = (e: any) => {
        setisTCPAgreed(e.target.checked);
    };
    const handleIsCPAgree = (e: any) => {
        setisCPAgreed(e.target.checked);
    }

    //予約確定ボタンが押されたときの処理
    const submit = async () => {
        try{
            submission.isTCPAgreed = isTCPAgreed;
            submission.isCPAgreed = isCPAgreed;
            if(submission.reservationFrame){
                const result = await API.post(AMPLIFYAPINAME, '/reservation', {body: submission});
                console.log('Success:', result);
                navigate('../reservation-complete');    
            }else if(submission.eventObject){
                const result = await API.post(AMPLIFYAPINAME, '/participant', {body: submission});
                console.log('Success:', result);
                navigate('../event-submission-complete');    
            }
        }catch(err){
            console.log('Error:', err);
        }
    };
    
    return(
        <>
            <Typography variant="h5" align="left" sx={{ mt: 4}}>オプション</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>オプション名</TableCell>
                        <TableCell>在庫数</TableCell>
                        <TableCell>申し込み数</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        options.map( (option, index) => (
                            <TableRow key={option.id}>
                                <TableCell>
                                    <Box>{option.optionName}</Box>
                                    <Box><Typography variant="caption">{option.description}</Typography></Box>
                                </TableCell>
                                <TableCell>{option.numberOfStocks ? String(option.numberOfStocks - option.numberOfReservedStocks) : 'あり'}</TableCell>
                                <TableCell sx={{display: !isConfirming ? '': 'none'}}>
                                    <TextField
                                        name={String(index)}
                                        size="small"
                                        sx={{width: 80}}
                                        type="number"
                                        InputProps={{
                                            inputProps: { 
                                                max: option.numberOfStocks ?  option.numberOfStocks - option.numberOfReservedStocks : null,
                                                min: 0,
                                                step: 1
                                            }
                                        }}
                                        onChange={handleOptionQuantityChange}
                                        value={selectedOptions[index].quantity}
                                    />
                                </TableCell>
                                <TableCell sx={{display: !isConfirming ? 'none': ''}}>{selectedOptions[index].quantity}</TableCell>
                            </TableRow>        
                        ))
                    }
                </TableBody>
            </Table>
            <Typography variant="h5" align="left" sx={{ mt: 4}}>{!isConfirming ? '申込情報の入力': '申込情報'}</Typography>
            <Grid container spacing={2} sx={{ mt: 1 , display: !isConfirming ? '': 'none'}}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        required
                        name="userName"
                        label="お名前"
                        type="text"
                        sx={{ width: 1 }}
                        inputRef={userNameRef}
                        error={userNameError}
                        helperText={userNameError && userNameRef?.current?.validationMessage}
                        inputProps={ {required: true} }
                        onChange={handleTextFieldValueChange}
                        value={userName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        name="numberOfPlayers"
                        label="お申込人数"
                        type="number"
                        sx={{ width: 1 }}
                        value={numberOfPlayers}
                        onChange={handleTextFieldValueChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        name="email"
                        label="メールアドレス"
                        type="text"
                        sx={{ width: 1 }}
                        inputRef={emailRef}
                        error={emailError}
                        helperText={emailError && emailRef?.current?.validationMessage}
                        inputProps={ {required: true, pattern: EmailVaildPattern} }     
                        onChange={handleTextFieldValueChange}
                        value={email}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        name="phoneNumber"
                        label="電話番号（ハイフンなし）"
                        type="tel"
                        sx={{ width: 1 }}
                        inputRef={phoneNumberRef}
                        error={phoneNumberError}
                        helperText={phoneNumberError && phoneNumberRef?.current?.validationMessage}
                        inputProps={ {required: true, pattern: PhoneNumberVaildPattern} }
                        onChange={handleTextFieldValueChange}
                        value={phoneNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="bikou"
                        label="備考"
                        multiline
                        rows={3}
                        fullWidth
                        onChange={handleTextFieldValueChange}
                        value={bikou}
                    />
                </Grid>
            </Grid>
            <Table sx={{ mt: 1 , display: !isConfirming ? 'none': ''}} >
                <TableHead>
                    <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>ご記入内容</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>お名前</TableCell>
                        <TableCell>{submission.user?.userName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>お申込人数</TableCell>
                        <TableCell>{String(submission.numberOfPlayers) + '名'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>メールアドレス</TableCell>
                        <TableCell>{submission.user?.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>電話番号</TableCell>
                        <TableCell>{submission.user?.phoneNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>備考</TableCell>
                        <TableCell>{submission.bikou}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography variant="h5" align="left" sx={{ mt: 4}}>キャンセルポリシー</Typography>
            <Table>
                <TableBody>
                    {
                        cancelPolicies.map( (cancelPolicy) => {
                            const cancelFeeType = cancelPolicy.cancelFeeType;
                            const startString = dateToDateString(cancelPolicy.dateToDateStart);
                            const endString = dateToDateString(cancelPolicy.dateToDateEnd);
                            const cancelFee = cancelFeeString(cancelPolicy);
                            return (
                                <TableRow key={cancelPolicy.id}>
                                    <TableCell>{`${startString}〜${endString}`}</TableCell>
                                    <TableCell>{`キャンセル料: ${cancelFee}`}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Grid container spacing={2} direction="row-reverse" sx={{ my: 2, pb: 5}}>
                <Grid item xs={12} sm={12} sx={{ mt: 1 , display: !isConfirming ? 'none': ''}}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left'
                    }}>
                        <Box>
                            <Checkbox checked={isTCPAgreed} onChange={handleisTCPAgree} />
                        </Box>
                        <Box>
                            <Typography><Link to={'/terms-and-conditions'} target="_blank">利用規約</Link>と<Link to={'/privacy-policy'} target="_blank">プライバシーポリシー</Link>に同意する</Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left'
                    }}>
                        <Box>
                            <Checkbox checked={isCPAgreed} onChange={handleIsCPAgree} />
                        </Box>
                        <Box>
                            <Typography>キャンセルポリシーに同意する</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} >
                    <Button variant="contained" size="large" sx={{ width: 1 , display: !isConfirming ? 'block': 'none' }} onClick={confirm} >確認画面へ</Button>
                </Grid>
                <Grid item xs={12} sm={4} >
                    <Button variant="outlined" size="large" sx={{ width: 1 , display: !isConfirming ? 'block': 'none' }} onClick={() => {navigate(-1)}} >戻る</Button>
                </Grid>                
                <Grid item xs={12} sm={8}>
                    <Button variant="contained" size="large" sx={{ width: 1 , display: !isConfirming ? 'none': 'block' }} onClick={submit} disabled={!isTCPAgreed || !isCPAgreed}>予約を確定する</Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button variant="outlined" size="large" sx={{ width: 1 , display: !isConfirming ? 'none': 'block' }} onClick={()=>{ setIsConfirming(false) }}>戻る</Button>
                </Grid>
            </Grid>
        </>
    )
};

export default SubmissionForm;