import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import SubmissionForm from './SubmissionForm';

const Reservation = () => {

    const location = useLocation();
    const [ submission ] = useState<SubmissionObject>(location.state as SubmissionObject)
    const [ reservationFrame ] = useState<ReservationFrameObject>(submission.reservationFrame!);

    const generateDateString = (unix: number) => {
        const jsWeekNames = [ "日", "月", "火", "水", "木", "金", "土" ];
        const thisDate = new Date(unix * 1000);
        return String(thisDate.getFullYear()) + '年' + String(thisDate.getMonth() + 1) + '月' + String(thisDate.getDate()) + '日（' + jsWeekNames[thisDate.getDay()] +'）';
    }

    return(
        <>
            <Typography variant="h4" align="left">{'フィールド「' + reservationFrame.fieldName + '」貸切予約フォーム'}</Typography>
            <Typography variant="h5" align="left" sx={{ mt: 4}}>内容</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>フィールド名</TableCell>
                        <TableCell>予約日付</TableCell>
                        <TableCell>予約枠</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{reservationFrame.fieldName}</TableCell>
                        <TableCell>{generateDateString(reservationFrame.date)}</TableCell>
                        <TableCell>{reservationFrame.subFieldName + ' ' + reservationFrame.frameName + '（' + reservationFrame.startTime + '~' + reservationFrame.endTime + '）'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <SubmissionForm reservationFrame={reservationFrame} />

        </>
    )

}

export default Reservation;