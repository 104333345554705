import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import './App.css';
import ResponsiveAppBar from './components/Nav';
import Home from './components/Home';
import Field from './components/Field';
import Reservation from './components/Reservation';
import ReservationComplete from './components/ReservationComplete';
import Event from './components/Event';
import EventSubmissionComplete from './components/EventSubmissionComplete';
import { PrivacyPolicy, TermsAndCondition, Error404 } from './components/Common';


function App() {
    return (
        <div className="App">
            <ResponsiveAppBar />
            <Container sx={{ mt: 2, pt: 4, backgroundColor: 'white'}}>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                        <Route path=":fieldId">
                            <Route index element={<Field />} />
                            <Route path="reservation" element={<Reservation />} />
                            <Route path="reservation-complete" element={<ReservationComplete />} />
                            <Route path=":eventId">
                                <Route index element={<Event />} />
                            </Route>
                            <Route path="event-submission-complete" element={<EventSubmissionComplete />} />
                        </Route>
                    </Route>
                    <Route path="terms-and-conditions" element={<TermsAndCondition/>}></Route>
                    <Route path="privacy-policy" element={<PrivacyPolicy/>}></Route>
                    <Route path="*" element={<Error404 />}></Route>
                </Routes>
            </Container>
        </div>
    );
}

export default App;
