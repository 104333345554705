import React, { useState, useLayoutEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Typography, Grid } from '@mui/material';
import SubmissionForm from './SubmissionForm';
const AMPLIFYAPINAME = 'fieldsystemuserapi';

const Event = () => {

    const { eventId } = useParams();
    const location = useLocation();
    const [ eventObject, setEventObject ] = useState<EventObject>();
    const fetchEvent = async () => {
        try{
            const eventObj: EventObject = await API.get(AMPLIFYAPINAME, '/event', { queryStringParameters: { id: eventId }});
            setEventObject(eventObj);
        }catch(err){
            console.log('Error:', err);
        }
    };

    useLayoutEffect(() => {
        fetchEvent();
    });

    const generateDateString = (unix: number) => {
        const jsWeekNames = [ "日", "月", "火", "水", "木", "金", "土" ];
        const thisDate = new Date(unix * 1000);
        return String(thisDate.getFullYear()) + '年' + String(thisDate.getMonth() + 1) + '月' + String(thisDate.getDate()) + '日（' + jsWeekNames[thisDate.getDay()] +'）';
    }

 
    return(
        <>
            <Typography variant="h4" align="left">{'イベント参加申し込みフォーム'}</Typography>
            <Typography variant="body1" align="left">{'開催フィールド: ' + eventObject?.fieldName}</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left">イベント名</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body1"  align="left">{eventObject?.eventName}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left">開催日時</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Typography variant="body1" align="left">{generateDateString(eventObject?.date ? eventObject!.date : 0)}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left">開催時間枠</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    {
                        eventObject?.usages.map( usage => (
                            <Typography key={usage.id} variant="body1" align="left">{usage.subFieldName + ' ' + usage.frameName + '（' + usage.startTime + '〜' + usage.endTime + '）'}</Typography>
                        ))
                    }
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left">概要</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body1" align="left">{eventObject?.description}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left">残枠数</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body1" align="left">{'残枠数: ' + String((eventObject?.capacity ? eventObject!.capacity : 0) - (eventObject?.numberOfParticipants ? eventObject!.numberOfParticipants : 0) + '人 / 定員:' + String(eventObject?.capacity ? eventObject!.capacity : 0) + '人')}</Typography>
                </Grid>
            </Grid>
            <div>
                {eventObject ? <SubmissionForm eventObject={eventObject} /> : 'Loading' }
            </div>
        </>
    )


}

export default Event;