import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Grid, Button } from '@mui/material';

const EventSubmissionComplete = () => {

    const navigate = useNavigate();

    return(
        <>
            <Typography variant="h4" align="left">イベント申し込み完了</Typography>
            <Grid container spacing={2} sx={{ mt: 1, pb: 5}}>
                <Grid item xs={12} sm={12}>
                    <Button variant="contained" size="large" fullWidth 
                        onClick={()=>{
                            navigate('..');
                        }}
                    >フィールドトップへ戻る</Button>                    
                </Grid>
            </Grid>
        </>
    )
}

export default EventSubmissionComplete;