import React, { useState, useEffect } from 'react';
import { Box, Paper, Table, Typography, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import Amplify, { API } from 'aws-amplify';
import AvailableDisplayCell from './AvailableDisplayCell';
const AMPLIFYAPINAME = 'fieldsystemuserapi';

const Field = () => {

    const { fieldId } = useParams();
    const [ field, setField ] = useState<FieldObject>();
    const [ subFields, setSubFields ] = useState<Array<SubFieldObject>>([]);
    const [ calendar, setCalendar ] = useState<Array<DateObject>>([]);
    const fetchField = async () => {
        try{
            const fieldObject: FieldObject = await API.get(AMPLIFYAPINAME, '/field', { queryStringParameters: { id: fieldId } });
            const calendarObject: Array<DateObject> = generateCalendar(fieldObject.reservationPeriodStart, fieldObject.reservationPeriodEnd);
            setField(fieldObject);
            setCalendar(calendarObject);
        }catch(err){
            console.log('Error:', err);
        }
    };
    const fetchSubFields = async () => {
        try{
            const subFieldArray: Array<SubFieldObject> = await API.get(AMPLIFYAPINAME, '/subFields', { queryStringParameters: { id: fieldId } });
            setSubFields(subFieldArray);
        }catch(err){
            console.log('Error:', err);
        }
    };
    const generateCalendar = (reservationPeriodStart: number, reservationPeriodEnd: number) => {
        const jsWeekNames = [ "日", "月", "火", "水", "木", "金", "土" ];
        const now = new Date();
        const firstDate = Math.floor(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0) / 1000 );
        const lastDate = firstDate + 60 * 60 * 24 * reservationPeriodEnd;
        let calendarList: Array<DateObject> = [];
        for(var i = 0; i < reservationPeriodEnd; i++){
            const thisDateUnix = firstDate + i * 60 * 60 * 24;
            const thisDate: Date = new Date(thisDateUnix * 1000);
            const dateObject: DateObject = {
                index: i,
                date: thisDateUnix,
                weekdayIndex: thisDate.getDay(),
                dateString: String(thisDate.getFullYear()) + '年' + String(thisDate.getMonth() + 1) + '月' + String(thisDate.getDate()) + '日（' + jsWeekNames[thisDate.getDay()] +'）',
                isWithInRange: i > reservationPeriodStart ? true : false
            };
            calendarList.push(dateObject);
        }
        return calendarList;
    }
    

    useEffect(() => {
        fetchField();
        fetchSubFields();
    }, []);

    return(
        <>
            <Typography variant="h5" align="left" gutterBottom>{`フィールド名: ${field?.fieldName ? field!.fieldName : '読込中'} - 予約カレンダー`}</Typography>
            <Typography variant="body1" align="left">予約したい時間枠/イベントをクリックすると予約画面に移動します。</Typography>
            {/* Table for PC */}
            <Table stickyHeader={true} sx={{display:{xs: "none", sm:"none",md:"table"}, mb: 4}} >
                <TableHead>
                    <TableRow>
                        <TableCell>日付</TableCell>
                        {
                            subFields.map( subField => (
                                <>
                                {
                                    subField.usageFrames.map( usageFrame =>(
                                        <TableCell
                                            sx={{ textAlign: "left" }}
                                            key={usageFrame.subFieldId + usageFrame.id}>{subField.subFieldName} {usageFrame.frameName} <br/>（{usageFrame.startTime}〜{usageFrame.endTime}） 
                                        </TableCell>
                                    ))
                                }
                                </>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        calendar.map(dateObject => (
                            <TableRow key={dateObject.index}>
                                <TableCell>{dateObject.dateString}</TableCell>
                                {
                                    subFields.map( subField => (
                                        <>
                                        {
                                            subField.usageFrames.map( usageFrame =>(
                                                <AvailableDisplayCell
                                                    key={subField.id + '_' + usageFrame.id + '_' + String(dateObject.date)}
                                                    isFieldAvailable={field!.availableWeekday[dateObject.weekdayIndex]}
                                                    isWithInRange={dateObject.isWithInRange}
                                                    fieldId={field!.id}
                                                    fieldName={field!.fieldName}
                                                    subFieldId={subField.id} 
                                                    subFieldName={subField.subFieldName}
                                                    frameId={usageFrame.id} 
                                                    frameName={usageFrame.frameName}
                                                    startTime={usageFrame.startTime}
                                                    endTime={usageFrame.endTime}
                                                    date={dateObject.date}
                                                />
                                            ))
                                        }
                                        </>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            {/* Table for Mobile */}
            <Table size="small" sx={{display:{xs: "flex", sm:"flex",md:"none"}}}>
                <TableBody>
                    { calendar.map((dateObject) => (
                        <TableRow key={dateObject.index}>
                            <TableContainer>
                                <Table size="small" style={{tableLayout:"fixed"}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{dateObject.dateString}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        {subFields.map( subField => (
                                            <>
                                            {
                                                subField.usageFrames.map( usageFrame =>(
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{ textAlign: "center" }}
                                                            key={usageFrame.subFieldId + usageFrame.id}>{subField.subFieldName} {usageFrame.frameName} <br/>（{usageFrame.startTime}〜{usageFrame.endTime}）
                                                        </TableCell>
                                                        <AvailableDisplayCell
                                                            key={subField.id + '_' + usageFrame.id + '_' + String(dateObject.date)}
                                                            isFieldAvailable={field!.availableWeekday[dateObject.weekdayIndex]}
                                                            isWithInRange={dateObject.isWithInRange}
                                                            fieldId={field!.id}
                                                            fieldName={field!.fieldName}
                                                            subFieldId={subField.id} 
                                                            subFieldName={subField.subFieldName}
                                                            frameId={usageFrame.id} 
                                                            frameName={usageFrame.frameName}
                                                            startTime={usageFrame.startTime}
                                                            endTime={usageFrame.endTime}
                                                            date={dateObject.date}
                                                        />
                                                    </TableRow>
                                                ))
                                            }
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );

};

export default Field;