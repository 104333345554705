import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Box, TableCell, Typography } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from "./field.module.css";
const AMPLIFYAPINAME = 'fieldsystemuserapi';

type AvailableDisplayCellProps = {
    key: string;
    isFieldAvailable: boolean;
    isWithInRange: boolean;
    fieldId: string;
    fieldName: string;
    subFieldId: string;
    subFieldName: string;
    frameId: string;
    frameName: string;
    startTime: string;
    endTime: string;
    date: number;
}

const AvailableDisplayCell: React.FC<AvailableDisplayCellProps> = (props) => {

    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ parentType, setParentType ] = useState<string>('reservation');
    const [ usage, setUsage] = useState<UsageObject>();
    const [ isAvailable, setIsAvailable ] = useState<boolean>(false);
    const [ event, setEvent ] = useState<EventObject>();
    const fetchAvailable = async () => {
        const usageObject: UsageObject = await API.get(AMPLIFYAPINAME, '/usage',{ queryStringParameters: { id: props.subFieldId + '_' + props.frameId + '_' + String(props.date) }});
        if(usageObject){
            setUsage(usageObject);
            if(usageObject.parentType === 'event'){
                const eventObject: EventObject = await API.get(AMPLIFYAPINAME, '/event', { queryStringParameters: {id: usageObject.parentId } });
                setEvent(eventObject);
                setParentType('event');
                setIsAvailable(eventObject.numberOfParticipants < eventObject.capacity);//定員に余裕があるときにtrue
            }else{
                setIsAvailable(false);
            }
        }else{
            setIsAvailable(true);
        }
        setIsLoading(false);
    };

    const handleCellClick = (e: any) => {
        if(parentType == 'reservation' && isAvailable === true && props.isWithInRange === true && props.isFieldAvailable === true){
            const reservationFrame: ReservationFrameObject = {
                fieldId: props.fieldId,
                fieldName: props.fieldName,
                subFieldId: props.subFieldId,
                subFieldName: props.subFieldName,
                frameId: props.frameId,
                frameName: props.frameName,
                startTime: props.startTime,
                endTime: props.endTime,
                date: props.date
            };
            const submission: SubmissionObject = {
                reservationFrame: reservationFrame
            };
            navigate('./reservation', {state: submission});
        }else if(parentType == 'event' && isAvailable === true){
            const submission: SubmissionObject = {
                eventObject: event
            };
            navigate('./' + event?.id, {state: submission});
        }
    };

    useEffect(()=>{
        fetchAvailable();
    },[]);
    
    return(
        <>
            <TableCell
                sx={{ textAlign: "left" }}
                className={ isAvailable && ((props.isWithInRange && props.isFieldAvailable) || parentType == 'event') ? styles.availableCellClass : ''}
                onClick={handleCellClick}
            >
                {(()=>{
                    if(isLoading){
                        return <HourglassEmptyIcon />;
                    }else if(parentType == 'event' && isAvailable === true){
                        return <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Box sx={{mr: 1}}>
                                <CircleOutlinedIcon />
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>{event!.eventName}</Typography>
                                <Typography variant="caption" display="block" gutterBottom>{"残枠数:" + String(event!.capacity - event!.numberOfParticipants) +"名 / 定員:" + String(event!.capacity) + "名"}</Typography>
                            </Box>
                        </Box>;
                    }else if(parentType == 'event' && isAvailable === false){
                        return <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Box sx={{mr: 1}}>
                                <ClearOutlinedIcon />
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>{event!.eventName}</Typography>
                                <Typography variant="caption" display="block" gutterBottom>{"満員 / 定員:" + String(event!.capacity) + "名"}</Typography>
                            </Box>
                        </Box>;
                    }else if(isAvailable === true && props.isWithInRange === true && props.isFieldAvailable === true){
                        //貸切予約可能
                        return <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Box sx={{mr: 1}}>
                                <CircleOutlinedIcon />
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>貸切予約</Typography>
                            </Box>
                        </Box>;
                    }else if(isAvailable === true && props.isWithInRange === false && props.isFieldAvailable === true){
                        //予約受付期間外
                        return <RemoveIcon />
                    }else{
                        //予約及びイベント申し込みどちらも不可
                        return <ClearOutlinedIcon />;
                    }
                })()}
            </TableCell>
        </>
    )
};

export default AvailableDisplayCell;