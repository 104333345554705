import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

export const Error404 = () => (
            <Grid container spacing={2} sx={{ minHeight: "200px" }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h4" align="left">ご指定のページは見つかりませんでした。</Typography>
                </Grid>
            </Grid>
);

export const TermsAndCondition = () => (
    <Box sx={{ textAlign: "left", p:5}}>
        <h2>利用規約</h2>
        <p>この利用規約（以下，「本規約」といいます。）は，フィールドシステム（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用するユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</p>
        <h3>第一条（適用）</h3>
        <li>本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
        <li>当社は、ユーザーによる本サービスの利用をもって、ユーザーが本規約に同意したものとみなし、本規約は、ユーザーに適用されるものとします。</li>
        <li>当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</li>
        <li>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
        <h3>第二条（禁止事項）</h3>
        <p>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</p>
        <ol type="1">
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
            <li>当社のサービスの運営を妨害するおそれのある行為</li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
            <li>当社，本サービスの他の利用者または第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為</li>
            <li>過度に暴力的な表現，露骨な性的表現，人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現，自殺，自傷行為，薬物乱用を誘引または助長する表現，その他反社会的な内容を含み他人に不快感を与える表現を，投稿または送信する行為</li>
            <li>営業，宣伝，広告，勧誘，その他営利を目的とする行為（当社の認めたものを除きます。），性行為やわいせつな行為を目的とする行為，面識のない異性との出会いや交際を目的とする行為，他のお客様に対する嫌がらせや誹謗中傷を目的とする行為，その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為</li>
            <li>宗教活動または宗教団体への勧誘行為</li>
            <li>その他，当社が不適切と判断する行為</li>
        </ol>
        <h3>第三条（本サービスの提供の停止等）</h3>
        <p>当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
        <ol type="1">
            <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
            <li>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
            <li>コンピュータまたは通信回線等が事故により停止した場合</li>
            <li>その他，当社が本サービスの提供が困難と判断した場合</li>
        </ol>
        <p>当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。</p>
        <h3>第四条（利用制限および登録抹消）</h3>
        <ol type="1">
            <li>当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。</li>
            <ol type="a">
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>登録事項に虚偽の事実があることが判明した場合</li>
                <li>破産，民事再生，会社更生または特別清算の手続開始決定等の申立がなされたとき</li>
                <li>当社からの連絡に対し，一定期間返答がない場合</li>
                <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
                <li>その他，当社が本サービスの利用を適当でないと判断した場合</li>
            </ol>
            <li>前項各号のいずれかに該当した場合，ユーザーは，当然に当社に対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。</li>
            <li>当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</li>
        </ol>
        <h3>第五条（保証の否認および免責事項）</h3>
        <ol type="1">
            <li>当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
            <li>当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。</li>
            <li>前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。</li>
            <li>当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</li>
        </ol>
        <h3>第六条（サービス内容の変更等）</h3>
        <p>当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</p>
        <h3>第七条（利用規約の変更）</h3>
        <p>当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。</p>
        <h3>第八条（個人情報の取扱い）</h3>
        <p>当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。</p>
        <h3>第九条（通知または連絡）</h3>
        <p>ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。</p>
        <h3>第十条（権利義務の譲渡の禁止）</h3>
        <p>ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</p>
        <h3>第十一条（準拠法・裁判管轄）</h3>
        <ol type="1">
            <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
            <li>本サービスに関して紛争が生じた場合には，横浜簡易裁判所または横浜地方裁判所を第一審の専属的合意管轄とします。</li>
        </ol>
        <p>以上</p>
    </Box>
)

export const PrivacyPolicy = () => (
    <Box sx={{ textAlign: "left", p:5}}>
        <h2>プライバシーポリシー</h2>
        <p>フィールドシステム（以下，「当社」といいます。）は，このウェブサイト上で提供するサービス（以下,「本サービス」といいます。）におけるプライバシー情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</p>
        <h3>第一条（プライバシー情報）</h3>
        <ol type="1">
            <li>プライバシー情報のうち「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報を指します。</li>
            <li>プライバシー情報のうち「履歴情報および特性情報」とは，上記に定める「個人情報」以外のものをいい，ご利用いただいたサービスやご購入いただいた商品，ご覧になったページや広告の履歴，ユーザーが検索された検索キーワード，ご利用日時，ご利用の方法，ご利用環境，郵便番号や性別，職業，年齢，ユーザーのIPアドレス，クッキー情報，位置情報，端末の個体識別情報などを指します。</li>
        </ol>
        <h3>第二条（プライバシー情報の収集方法）</h3>
        <ol>
            <li>当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や，決済に関する情報を当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。</li>
            <li>当社は，ユーザーについて，利用したサービスやソフトウエア，閲覧したページや広告の履歴，利用日時，利用方法，利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態，利用に際しての各種設定情報なども含みます），IPアドレス，クッキー情報，位置情報，端末の個体識別情報などの履歴情報および特性情報を，ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。</li>
        </ol>
        <h3>第三条（個人情報を収集・利用する目的）</h3>
        <p>当社が個人情報を収集・利用する目的は，以下のとおりです。</p>
        <ol>
            <li>ユーザーに自分の登録情報の閲覧や修正，利用状況の閲覧を行っていただくために，氏名，住所，連絡先，支払方法などの登録情報，利用されたサービスおよびそれらの代金などに関する情報を表示する目的</li>
            <li>ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合やユーザーに商品を送付したり必要に応じて連絡したりするため，氏名や住所などの連絡先情報を利用する目的</li>
            <li>フィールドの予約申込・イベント参加申込・その他申込・問合せに関するご連絡（フィールド運営者に対する予約申込・イベント参加申込・その他申込・問合せ内容の伝達、ユーザーに対するフィールド運営者からの回答等の伝達、利用履歴の確認、その他当社サービスの提供のために必要な連絡）に利用する目的</li>
            <li>ユーザーの本人確認を行うために，氏名，生年月日，住所，電話番号，銀行口座番号，クレジットカード番号，運転免許証番号，配達証明付き郵便の到達結果などの情報を利用する目的</li>
            <li>ユーザーに代金を請求するために，購入された商品名や数量，利用されたサービスの種類や期間，回数，請求金額，氏名，住所，銀行口座番号やクレジットカード番号などの支払に関する情報などを利用する目的</li>
            <li>ユーザーが簡便にデータを入力できるようにするために，当社に登録されている情報を入力画面に表示させたり，ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的</li>
            <li>代金の支払を遅滞したり第三者に損害を発生させたりするなど，本サービスの利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために，利用態様，氏名や住所など個人を特定するための情報を利用する目的</li>
            <li>ユーザーからのお問い合わせに対応するために，お問い合わせ内容や代金の請求に関する情報など当社がユーザーに対してサービスを提供するにあたって必要となる情報や，ユーザーのサービス利用状況，連絡先情報などを利用する目的</li>
            <li>上記の利用目的に付随する目的</li>
        </ol>
        <h3>第四条（個人情報の第三者提供）</h3>
        <p>当社は、ご本人の同意を得ずに、第三者に個人情報を提供しません。</p>
        <ul>
            <li>第三者に提供する目的<br/>フィールドの予約申込・イベント参加申込・その他申込・問合せに関するご連絡（フィールド運営者に対する予約申込・イベント参加申込・その他申込・問合せ内容の伝達、ユーザーに対するフィールド運営者からの回答等の伝達、利用履歴の確認、その他当社サービスの提供のために必要な連絡）に利用する目的</li>
            <li>提供する個人情報の項目<br/>ユーザー情報、フィールドの予約申込情報、イベント参加申込情報・その他申込情報</li>
            <li>提供の手段または方法<br/>予約等管理システム、メール、ウェブページへの掲載・アップロード</li>
            <li>当該情報の提供を受ける組織の種類および属性<br/>フィールド運営者</li>
        </ul>
        <p>ただし，個人情報保護法その他の法令で認められる場合を除きます。</p>
        <ol type="a">
            <li>法令に基づく場合</li>
            <li>人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</li>
            <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき</li>
            <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            <li>予め次の事項を告知あるいは公表をしている場合</li>
            <ol type="i">
                <li>利用目的に第三者への提供を含むこと</li>
                <li>第三者に提供されるデータの項目</li>
                <li>第三者への提供の手段または方法</li>
                <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
            </ol>
        </ol>
        <p>前項の定めにかかわらず，次に掲げる場合は第三者には該当しないものとします。</p>
        <ol>
            <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
            <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
            <li>個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いているとき</li>
        </ol>
        <h3>第五条（個人情報の開示）</h3>
        <ol>
            <li>当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，１件あたり１，０００円の手数料を申し受けます。</li>
            <ol>
                <li>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</li>
                <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                <li>その他法令に違反することとなる場合</li>
            </ol>
            <li>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</li>
        </ol>
        <h3>第六条（個人情報の訂正および削除）</h3>
        <ol>
            <li>ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正または削除を請求することができます。</li>
            <li>当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正または削除を行い，これをユーザーに通知します。</li>
        </ol>
        <h3>第七条（個人情報の利用停止等）</h3>
        <p>当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行い，その結果に基づき，個人情報の利用停止等を行い，その旨本人に通知します。ただし，個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じます。</p>
        <h3>第八条（プライバシーポリシーの変更）</h3>
        <ol>
            <li>本ポリシーの内容は，ユーザーに通知することなく，変更することができるものとします。</li>
            <li>当社が別途定める場合を除いて，変更後のプライバシーポリシーは，このウェブサイト上に掲載したときから効力を生じるものとします。</li>
        </ol>
        <h3>第九条（お問い合わせ窓口）</h3>
        <p>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</p>
        <p>フィールドシステム</p>
        <p>お問い合わせ窓口</p>
        <p>contact＠fieldsystem-svg.com</p>
    </Box>
)